import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated for React 18
import Favicon from 'react-favicon';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Switch is replaced with Routes
import App from './App.js';

const root = ReactDOM.createRoot(document.getElementById('root')); // Updated for React 18

root.render(
  <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} /> {/* Use element instead of component */}
      </Routes>
      <Favicon url="http://aliciamersy.com/favicon.png" />
    </BrowserRouter>
  </div>
);