import React, { Component } from 'react';
import "./App.css";
import HorizontalMarquee from "./HorizontalMarquee.js";
import SecondBackground from "./SecondBackground.js";
import ThirdBackground from "./ThirdBackground.js";
import FourthBackground from "./FourthBackground.js";
import FifthBackground from "./FifthBackground.js";
import SeventhBackground from "./SeventhBackground.js";
import EightBackground from "./EightBackground.js";
import NinthBackground from "./NinthBackground.js";
import InsertOne from "./InsertOne.js";
import InsertHeadline from "./InsertHeadline.js";
require('es6-promise').polyfill();
require('isomorphic-fetch');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleChars: 0,
      issPosition: [],
      index_data_home: this.props.index_data || null
    };
  }

  reset() {
    this.setState({
      visibleChars: 0
    });
  }

  static defaultProps = {
    interval: 1000,
    callback: null
  };

  componentDidMount() {
    if (this.props) {
      this.setState({
        index_data_home: this.props.index_data
      });
    }
  }

  randomize(list) {
    const randomized = [];
    while (list.length) {
      const index = Math.floor(Math.random() * list.length);
      randomized.push(list.splice(index, 1)[0]);
    }
    return randomized;
  }

  cmsToImages(fold_number) {
    return this.state.index_data_home
      .filter((ele) => ele.title === fold_number)
      .map((ele, index) => ({
        key: index,
        className: ele.details,
        source: ele.lien
      }));
  }

  renderImages(fold_number) {
    const images = this.randomize(this.cmsToImages(fold_number));
    return images.map((ele) => (
      <div key={ele.key}>
        <div className="photo-content">
          <img role="presentation" className={ele.className} src={ele.source} />
        </div>
        <HorizontalMarquee data={this.props.horizontal_marquee_data} />
      </div>
    ));
  }

  render() {
    if (!this.state.index_data_home) {
      return <div>Loading</div>;
    }

    return (
      <div>
        {this.renderImages("fold_1")}
        <SecondBackground
          data={this.props.inter_fold_2}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_2")}
        <InsertHeadline
          index={2}
          className="insert_two"
          data={this.props.black_headlines}
        />
        <ThirdBackground
          data={this.props.inter_fold_3}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_3")}
        <FourthBackground
          data={this.props.inter_fold_4}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_4")}
        <FifthBackground
          data={this.props.inter_fold_5}
          blackHeadlines={this.props.black_headlines}
        />
        <InsertHeadline
          index={5}
          className="insert_one"
          data={this.props.black_headlines}
        />
        {this.renderImages("fold_5")}
        {this.renderImages("fold_6")}
        <SeventhBackground
          data={this.props.inter_fold_6}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_7")}
        <EightBackground
          data={this.props.inter_fold_7}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_8")}
        <NinthBackground
          data={this.props.inter_fold_8}
          blackHeadlines={this.props.black_headlines}
        />
        {this.renderImages("fold_9")}
      </div>
    );
  }
}

export default Home;
