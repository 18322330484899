import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar.js';
import FixedImgs from './FixedImgs.js';
import FixedComponents2 from './FixedComponents2.js';
import FirstBackground from './FirstBackground.js';
import Projects from './Projects.js';
import AboutPage from './AboutPage.js';
import Home from './Home.js';
import axios from 'axios';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: true,
      projects: false,
      about: false,
      master_data: null,
      index_data: null,
      black_headlines: null,
      background_words_slider_top: null,
      background_words_slider_bottom: null,
      first_fold_oval_animation: null,
      about_page: null,
      backgroundColor: 'white',
    };
  }

  _renderIndex = () => {
    this.setState({
      index: true,
      projects: false,
      about: false,
      backgroundColor: 'white',
    });
  };

  _renderAbout = () => {
    this.setState({
      index: false,
      projects: false,
      about: true,
      backgroundColor: 'transparent',
    });
  };

  _renderProjects = () => {
    this.setState({
      index: false,
      projects: true,
      about: false,
      backgroundColor: 'white',
    });
  };

  componentDidMount() {
    axios
      .get('https://sheet.best/api/sheets/63ebfc33-0b2e-4af1-82cd-31669f9a0bc7')
      .then((response) => {
        const main_data = response.data;
        this.setState({
          master_data: main_data,
          index_data: main_data.filter((ele) => ele.section === 'Index_page'),
          horizontal_marquee_data: main_data.filter((ele) => ele.section === 'Horizontal_marquee'),
          inter_fold_1: main_data.filter((ele) => ele.title === 'inter_fold_1'),
          inter_fold_2: main_data.filter((ele) => ele.title === 'inter_fold_2'),
          inter_fold_3: main_data.filter((ele) => ele.title === 'inter_fold_3'),
          inter_fold_4: main_data.filter((ele) => ele.title === 'inter_fold_4'),
          inter_fold_5: main_data.filter((ele) => ele.title === 'inter_fold_5'),
          inter_fold_6: main_data.filter((ele) => ele.title === 'inter_fold_6'),
          inter_fold_7: main_data.filter((ele) => ele.title === 'inter_fold_7'),
          inter_fold_8: main_data.filter((ele) => ele.title === 'inter_fold_8'),
          black_headlines: main_data
            .filter((ele) => ele.title === 'black_headlines')
            .map((ele) => ele.desc),
          background_words_slider_top: main_data
            .filter((ele) => ele.title === 'background_words_slider_top')
            .map((ele) => ele.desc),
          background_words_slider_bottom: main_data
            .filter((ele) => ele.title === 'background_words_slider_bottom')
            .map((ele) => ele.desc),
          background_fixed_img: main_data.filter((ele) => ele.title === 'background_fixed_img'),
          first_fold_oval_animation: main_data.filter((ele) => ele.title === 'first_fold_oval_animation'),
          about_page: main_data.filter((ele) => ele.section === 'About_page'),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { backgroundColor, index, projects, about, master_data, index_data } = this.state;

    if (!master_data || !index_data) {
      return (
        <div className="loading_screen">
          <div className="loading_screen_inner">
            <h1>LOADING</h1>
          </div>
        </div>
      );
    }

    const style = { position: 'relative', top: '0' };

    if (index) {
      return (
        <div className="full-page">
          <div>
            <div className="Fixed_infos" style={{ backgroundColor }}>
              <p>
                <a onClick={this._renderProjects}>Work</a>
                <a onClick={this._renderAbout}>About</a>
                <a href="mailto:aliciamersy@gmail.com">Contact</a>
              </p>
            </div>
          </div>
          <NavBar {...this.state} />
          <FixedImgs {...this.state} />
          <FirstBackground {...this.state} />
          <FixedComponents2 {...this.state} />
          <Home {...this.state} />
          <div className="info_mobile">
            <a onClick={this._renderProjects}>Work</a>
            <a onClick={this._renderAbout}>About</a>
            <a href="mailto:aliciamersy@gmail.com">Contact</a>
          </div>
        </div>
      );
    } else if (projects) {
      return (
        <div className="full-page">
          <div className="info_mobile" style={style}>
            <a onClick={this._renderIndex}>Index</a>
            <a onClick={this._renderAbout}>About</a>
            <a href="mailto:aliciamersy@gmail.com">Contact</a>
          </div>
          <div>
            <div className="Fixed_infos" style={{ backgroundColor }}>
              <p>
                <a onClick={this._renderIndex}>Index</a>
                <a onClick={this._renderAbout}>About</a>
                <a href="mailto:aliciamersy@gmail.com">Contact</a>
              </p>
            </div>
          </div>
          <Projects />
        </div>
      );
    } else if (about) {
      return (
        <div className="full-page">
          <div className="info_mobile" style={style}>
            <a onClick={this._renderIndex}>Index</a>
            <a onClick={this._renderProjects}>Work</a>
            <a href="mailto:aliciamersy@gmail.com">Contact</a>
          </div>
          <div>
            <div className="Fixed_infos" style={{ backgroundColor }}>
              <p>
                <a onClick={this._renderIndex}>Index</a>
                <a onClick={this._renderProjects}>Work</a>
                <a href="mailto:aliciamersy@gmail.com">Contact</a>
              </p>
            </div>
          </div>
          <AboutPage {...this.state} />
        </div>
      );
    }
  }
}

export default App;
